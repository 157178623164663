<template>
  <div>
    <b-modal
      id="simple-protocol-modal"
      hide-header
      hide-footer
      centered
      size="md"
      @hidden="closeModal()"
    >
      <div class="modal-header">
        <div class="modal-title">Salvar protocolo</div>
        <span class="icon-box"
          ><v-close class="icon stroke" @click="closeModal()"
        /></span>
      </div>
      <div class="around-content">
        <validation-observer ref="ProtocolForm">
          <b-row>
            <b-col cols="12">
              <b-form-group>
                <label for="nome_protocolo">Nome do protocolo</label>
                <input
                  id="nome_protocolo"
                  autocomplete="off"
                  type="text"
                  placeholder="Ex: Glaucoma fase 01"
                  class="form-control"
                  v-model="form.name"
                />
                <div
                  v-if="validated && !form.name"
                  class="custom-invalid-feedback"
                >
                  Campo obrigatório
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="5">
              <b-form-group>
                <label for="" class="d-block">Privacidade</label>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="privacidade"
                    id="inlineRadio1"
                    v-model="form.type"
                    value="privado"
                    checked
                  />
                  <label class="form-check-label" for="inlineRadio1"
                    >Privado</label
                  >

                  <div class="form-check form-chek-inline">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="privacidade"
                      id="inlineRadio2"
                      value="compartilhado"
                      v-model="form.type"
                    />
                    <label class="form-check-label" for="inlineRadio2"
                      >Compartilhado</label
                    >
                  </div>
                </div>
                <div
                  v-if="validated && !form.type"
                  class="custom-invalid-feedback"
                >
                  Campo obrigatório
                </div>
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
        <hr />

        <div class="wrapper-button">
          <b-button variant="primary" size="lg" @click="createProtocol()">
            Salvar protocolo
          </b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Close from '@/assets/icons/close.svg'

export default {
  name: 'SimpleProtocolModal',
  components: {
    'v-close': Close
  },
  props: {
    protocolMedicines: Array
  },
  data() {
    const clinic = JSON.parse(localStorage.getItem('clinic'))
    return {
      clinic_id: clinic.id,
      person: JSON.parse(localStorage.getItem('user')),
      form: {
        name: null,
        type: null,
      },
      validated: null
    }
  },
  methods: {
    closeModal() {
      this.$bvModal.hide('simple-protocol-modal')
      this.clearForm()
    },
    clearForm() {
      this.form = {
        name: null,
        type: null
      }
      this.validated = null
    },
    isValidForm() {
      this.validated = true
      return this.form.name && this.form.type
    },
    async createProtocol() {
      if (!this.isValidForm()) {
        return
      }
      const protocolMedicines = []

      this.protocolMedicines.forEach(protocolMedicine => {
        protocolMedicines.push({
          via: protocolMedicine.via,
          quantity: protocolMedicine.quantidade,
          dose: protocolMedicine.dose,
          time_of_use: protocolMedicine.tempo_uso,
          intervals: protocolMedicine.intervalo,
          orientation: protocolMedicine.orientacao,
          medicine: protocolMedicine.medicamento,
          antimicrobial: protocolMedicine.antimicrobiano,
        })
      })
      const data = {
        name: this.form.name,
        type: this.form.type,
        clinic_id: this.clinic_id,
        person_id: this.person.id,
        protocol_medicines: protocolMedicines
      }

      const loading = this.$loading.show()
      try {
        await this.api.createProtocol(data)
        this.$toast.success('Protocolo salvo com sucesso!')
        this.closeModal()
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        loading.hide()
      }
    }
  }
}
</script>

<style lang="scss">
#simple-protocol-modal {
  .modal-body {
    padding: 0 !important;
    margin: 0 !important;

    .modal-header {
      width: 100%;
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      padding: 24px !important;

      .modal-title {
        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 28px;
        color: var(--type-active);
      }

      .icon {
        width: 25px;
        fill: var(--neutral-500);
      }
    }
  }

  .around-content {
    padding: 24px 24px 0 24px;
    .form-check-inline {
      margin-top: 3%;
      .form-check-label {
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
      }
    }
  }

  .wrapper-button {
    width: 100%;
    display: flex;
    margin: 20px 0 !important;
    justify-content: flex-end;

    button {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
    }
  }
}
</style>
