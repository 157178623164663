<template>
  <div>
    <b-modal
      id="protocols-select-modal"
      hide-header
      hide-footer
      static
      centered
      size="xl"
      @hidden="closeModal"
    >
      <div class="modal-header">
        <div class="modal-title">Protocolos recomendados</div>
        <span class="icon-box"
          ><v-close class="icon stroke" @click="closeModal()"
        /></span>
      </div>
      <div class="around-content">
        <b-row>
          <b-col cols="12">
            <b-form-group class="form-group">
              <label for="search">Buscar por doença</label>
              <b-form-input
                placeholder="Pesquise por uma doença"
                autocomplete="off"
                id="search"
                debounce="500"
                v-model="query"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <span class="protocols-selected"
            >Protocolos selecionados ({{ selected.length }})</span
          >
          <v-protocol-table
            v-for="protocol in selected"
            :key="protocol.id"
            :protocol="protocol"
            :modal="true"
            :selectedDefault="true"
            @selected-protocols="selectProtocols"
            @unselected-protocols="unselectProtocols"
          />
        </b-row>
        <v-tab
          class="v-tab"
          :index="currentTabIndex"
          ref="protocols_tabs"
          v-bind:keys="[
            'Todos',
            'Favoritos',
            'Recomendados',
            'Literatura Médica',
            `${clinic.name}`,
            'Meus protocolos'
          ]"
          fontSize="1em"
          v-on:onChange="onChangeTab"
        >
          <v-shared-protocols-tab
            :modal="true"
            v-if="currentTabIndex === 0"
            :onChangeTab="onChangeTab"
            @select-protocols="selectProtocols"
            @unselect-protocols="unselectProtocols"
            :selectProtocols="selected"
            :queryName="query"
            :doctorOrDoctorManager="false"
          />
          <protocol-favorites-tab
            :modal="true"
            v-if="currentTabIndex === 1"
            :onChangeTab="onChangeTab"
            @select-protocols="selectProtocols"
            @unselect-protocols="unselectProtocols"
            :selectProtocols="selected"
            :queryName="query"
            :person="person"
          />

          <recommended-procols-tab
            :modal="true"
            v-if="currentTabIndex === 2"
            :onChangeTab="onChangeTab"
            @select-protocols="selectProtocols"
            @unselect-protocols="unselectProtocols"
            :selectProtocols="selected"
            :queryName="query"
            :person="person"
          />

          <medical-literature-protocols
            :modal="true"
            v-if="currentTabIndex === 3"
            :onChangeTab="onChangeTab"
            :selectProtocols="selected"
            @select-protocols="selectProtocols"
            @unselect-protocols="unselectProtocols"
            :queryName="query"
            :person="person"
          />

          <clinic-protocol-tab
            :modal="true"
            v-if="currentTabIndex === 4"
            :onChangeTab="onChangeTab"
            :selectProtocols="selected"
            @select-protocols="selectProtocols"
            @unselect-protocols="unselectProtocols"
            :queryName="query"
            :person="person"
          />

          <my-protocols-tab
            :modal="true"
            v-if="currentTabIndex === 5"
            :onChangeTab="onChangeTab"
            :selectProtocols="selected"
            @select-protocols="selectProtocols"
            @unselect-protocols="unselectProtocols"
            :queryName="query"
            :person="person"
          />
        </v-tab>
        <hr />
        <label class="checkbox">
          <input
            type="checkbox"
            id="infoPaciente"
            v-model="infoPaciente"
            :disabled="false"
          />
          <span class="checkmark">
            <Check />
          </span>
          Inserir dados do paciente na impressão (Data de nascimento, CPF e endereço)
        </label>
        <div class="wrapper-button">
          <b-button
            variant="primary"
            :disabled="!isSelected"
            size="lg"
            @click="addProtocols()"
          >
            Adicionar protocolos
          </b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { track } from '@/utils/mixPanel'
import Close from '@/assets/icons/close.svg'
import Tab from '@/components/Tab.vue'
import SharedProtocolsTab from '@/views/Protocols/SharedProtocolsTab.vue'
import MyProtocolsTab from '@/views/Protocols/MyProtocolsTab.vue'
import ProtocolFavoritesTab from '@/views/Protocols/ProtocolFavoritesTab.vue'
import RecommendedProtocolsTab from '@/views/Protocols/RecommendedProtocolsTab.vue'
import ClinicProtocolTab from '@/views/Protocols/ClinicProtocolTab.vue'
import MedicalLiteratureProtocols from '@/views/Protocols/MedicalLiteratureProtocols.vue'
import ProtocolTable from '@/components/Protocols/ProtocolTable.vue'
import Check from '@/assets/icons/check.svg'

export default {
  name: 'SelectProtocolsModal',
  components: {
    'v-close': Close,
    'v-tab': Tab,
    'v-shared-protocols-tab': SharedProtocolsTab,
    'v-protocol-table': ProtocolTable,
    'my-protocols-tab': MyProtocolsTab,
    'protocol-favorites-tab': ProtocolFavoritesTab,
    'recommended-procols-tab': RecommendedProtocolsTab,
    'clinic-protocol-tab': ClinicProtocolTab,
    'medical-literature-protocols': MedicalLiteratureProtocols,
    Check
  },
  data() {
    return {
      query: null,
      currentTabIndex: 0,
      clinic: JSON.parse(localStorage.getItem('clinic')),
      person: JSON.parse(localStorage.getItem('user')),
      selected: [],
      isSelected: false,
      infoPaciente: false,
    }
  },
  methods: {
    closeModal() {
      this.$bvModal.hide('protocols-select-modal')
      this.selected = []
      this.isSelected = false
      this.query = null
      this.currentTabIndex = 0
    },
    onChangeTab(index) {
      this.currentTabIndex = index
    },
    addProtocols() {
      this.selected.map(protocol =>
        track('protocolo_utilizado', {
          clinic: this.clinic.name,
          name: protocol.name,
          type: protocol.type,
          pharmacist_name: protocol.pharmacist_name,
        })
      );
      this.$emit('selected-protocols', this.selected)
      this.closeModal();
    },
    selectProtocols(value) {
      const newValue = value.medicines.map(medicine => {
        return {
          ...medicine, 
          infoPaciente: this.infoPaciente
        }
      })
      value.medicines = newValue
      this.selected.push(value)
    },
    unselectProtocols(value) {
      const index = this.selected.findIndex(protocol => protocol.id === value)
      this.selected.splice(index, 1)
    }
  },
  watch: {
    selected() {
      if (this.selected.length === 0) {
        this.isSelected = false
      } else {
        this.isSelected = true
      }
    },
    infoPaciente(value) {
      const newValue = this.selected.map(item => {
        return {
          ...item,
          medicines: item.medicines.map(medicine => {
            return {
              ...medicine,
              infoPaciente: value
            }
          })
        }
      })
      this.selected = newValue
    }
  }
}
</script>

<style lang="scss">
#protocols-select-modal {
  .modal-body {
    padding: 0 !important;
    margin: 0 !important;

    .modal-header {
      width: 100%;
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      padding: 24px !important;

      .modal-title {
        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 28px;
        color: var(--type-active);
      }

      .icon {
        width: 25px;
        fill: var(--neutral-500);
      }
    }
  }

  .around-content {
    padding: 24px 24px 0 24px;
    .form-check-inline {
      margin-top: 3%;
      .form-check-label {
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
      }
    }

    .protocols-selected {
      margin-left: 1%;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 150%;
      color: var(--type-active);
    }

    .v-tab {
      max-height: 500px;
      overflow-y: auto;
    }
  }

  .wrapper-button {
    width: 100%;
    display: flex;
    margin: 20px 0 !important;
    justify-content: flex-end;

    button {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
    }
  }
}
</style>
